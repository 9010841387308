import React, { FC } from "react";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import tableDesktop from "../../images/ourstoryMainSquare.png";
import servicesthankyou from "../../images/servicesthankyou.webp";
// import servicesthankyoumobile from "../../images/mobile-images/servicesthankyoumobile.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";
import { generateThanksMsg } from "./ThankYou.helper";
import translate from "../../i18n/translate";

type Props = {
  location: any;
  isServicesPage?: boolean;
};

const ThankYouMain: FC<Props> = ({ location, isServicesPage = false }) => {
  const { width } = UseWindowSize();
  return (
    <>
      {width < 1200 ? (
        <HeroBannerMobile
          heading={translate("thankyou-title")}
          description={generateThanksMsg(location)}
          homeBackground={isServicesPage ? servicesthankyou : tableDesktop}
          alt="Thank you"
          buttonText={translate("thankyou-backHome")}
          date=""
          link="/"
        />
      ) : (
        <HeroBanner
          heading={translate("thankyou-title")}
          description={generateThanksMsg(location)}
          homeBackground={isServicesPage ? servicesthankyou : tableDesktop}
          buttonText={translate("thankyou-backHome")}
          date=""
          link="/"
        />
      )}
    </>
  );
};

export default ThankYouMain;
