import translate from "../../i18n/translate";
//  TODO: MARKO REMOVE THIS SHIT FIX
export const ThankYouMessage = [
  {
    path: "/subscribe/thank-you",
    msg: translate("thankyou-subscribe"),
  },
  {
    path: "/subscribe/thank-you/",
    msg: translate("thankyou-subscribe"),
  },
  {
    path: "/reports/thank-you",
    msg: translate("thankyou-reports"),
  },
  {
    path: "/reports/thank-you/",
    msg: translate("thankyou-reports"),
  },
  {
    path: "/webinars/thank-you",
    msg: translate("thankyou-webinars"),
  },
  {
    path: "/webinars/thank-you/",
    msg: translate("thankyou-webinars"),
  },
  {
    path: "/contact/thank-you",
    msg: translate("thankyou-contact"),
  },
  {
    path: "/contact/thank-you/",
    msg: translate("thankyou-contact"),
  },
  {
    path: "/newsletter/thank-you",
    msg: translate("thankyou-newsletter"),
  },
  {
    path: "/newsletter/thank-you/",
    msg: translate("thankyou-newsletter"),
  },
  {
    path: "/solutions/thank-you",
    msg: translate("thankyou-solutions"),
  },
  {
    path: "/solutions/thank-you/",
    msg: translate("thankyou-solutions"),
  },
  {
    path: "/case-study/thank-you",
    msg: translate("thankyou-casestudy"),
  },
  {
    path: "/case-study/thank-you/",
    msg: translate("thankyou-casestudy"),
  },
  {
    path: "/thank-you/",
    msg: translate("thankyou-contact"),
  },
  {
    path: "/thank-you",
    msg: translate("thankyou-contact"),
  },
];

export const generateThanksMsg = (pathOfPage: string) => {
  let thanksMessage = ThankYouMessage.find(x => x.path === pathOfPage);
  return thanksMessage?.msg;
};
